document.addEventListener('DOMContentLoaded', function () {
    let triggers = document.querySelectorAll('.header__mob-categories-list span span');
    triggers = Array.from(triggers).filter((t) => t.nextElementSibling?.tagName === 'NAV');

    const subMenuList = document.querySelectorAll('.header__mob-categories-list .header__mob-subcategory-list');

    triggers.forEach((item, i) => {
        item.addEventListener('click', () => {
            subMenuList[i].classList.toggle('hidden-lg');
            item.querySelector('img').classList.toggle('rotate-180');
        })
    })
});