const burgerMenuMob = document.querySelector('.hamburger-menu-mob');
const burgerMenuDesktop = document.querySelector('.hamburger-menu-desktop');

const searchEl = document.querySelector('.header__search-btn-mob');
const mobileWrapper = document.querySelector('.header__mobile-wrapper');

const menuMobileContent = document.querySelector('.header__menu-mobile-wrapper');
const searchMobileContent = document.querySelector('.header__search-mobile-wrapper');
const menuDesktopContent = document.querySelector('.desktop-menu-popup');

const toggleMobileMenuVisibility = function (expanded) {
  const mobStyle = mobileWrapper.style.top;
  const bodyStyle = document.body.style.overflow;
  mobileWrapper.style.top = mobStyle === '-2000px' ? '68px' : '-2000px'
  mobileWrapper.style.opacity = mobStyle === '-2000px' ? 1 : 0;
  document.body.style.overflow = bodyStyle === 'hidden' ? 'auto' : 'hidden';
  searchMobileContent.style.display = 'none';
  menuMobileContent.style.display = 'none';
  burgerMenuMob.setAttribute('aria-expanded', expanded);
}

const toggleSearchMenu = () => {
  toggleMobileMenuVisibility();
  const searchStyle = searchMobileContent.style.display;
  searchMobileContent.style.display = searchStyle === 'block' ? 'none' : 'block';
  searchMobileContent.querySelector('input')?.focus();
}

const toggleMobileMenu = function (e) {
  const expanded = burgerMenuMob.getAttribute('aria-expanded') === 'true'
    || searchMobileContent.style.display === 'block';
  toggleMobileMenuVisibility(expanded);
  const menuStyle = menuMobileContent.style.display;
  burgerMenuMob.setAttribute('aria-expanded', !expanded);
  menuMobileContent.style.display = menuStyle === 'flex' ? 'none' : 'flex';
  e.preventDefault();
}

burgerMenuMob.addEventListener('click', toggleMobileMenu);
searchEl.addEventListener('click', toggleSearchMenu);

document.addEventListener("click", function (e) {
  if (e.target.className === 'header__mobile-wrapper')
    toggleMobileMenuVisibility();
});


const toggleDesktopMenu = function (e) {
  const bodyStyle = document.body.style.overflow;
  const menuStyle = menuDesktopContent.style.left || '-4000px';
  const expanded = burgerMenuDesktop.getAttribute('aria-expanded') === 'true';
  burgerMenuDesktop.setAttribute('aria-expanded', !expanded);

  menuDesktopContent.style.left = menuStyle === '-4000px' ? '0px' : '-4000px'
  menuDesktopContent.style.opacity = menuStyle === '-4000px' ? 1 : 0;
  document.body.style.overflow = bodyStyle === 'hidden' ? 'auto' : 'hidden';
  e.preventDefault();
}

burgerMenuDesktop.addEventListener('click', toggleDesktopMenu);

const languageMenuBtn = document.querySelector('button.header__languages-list-btn');

if (languageMenuBtn) {
  const languageListMobile = languageMenuBtn.parentElement;
  languageMenuBtn.addEventListener('click', () => {
    languageListMobile.classList.toggle('header__languages-list_active')
  });
  document.body.addEventListener('click', (e) => {
    if (!e.target.classList.contains('header__languages-list-btn'))
      languageListMobile.classList.remove('header__languages-list_active')
  });
}