document.addEventListener('DOMContentLoaded', function () {
    const triggers = document.querySelectorAll('.footer__news-group-arrow');
    const subMenuList = document.querySelectorAll('.footer__news-sub');

    triggers.forEach((item, i) => {
        item.addEventListener('click', () => {
            subMenuList[i].classList.toggle('hidden-lg');
            item.querySelector('img').classList.toggle('rotate-180');
        })
    })
});