function getCookie(name) {
  let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

let cookiecook = getCookie("cookiecook"),
  cookiewin = document.getElementsByClassName('cookie')[0];

const closeBtn = document.querySelector('.cookie__close-btn');

if (closeBtn) {
  closeBtn.onclick = () => {
    cookiewin.classList.add('cookie_hidden');
  }
}

if (cookiecook != "no") {
  cookiewin.classList.remove('cookie_hidden');
  cookiewin.classList.add("show");
  const bottomNavbar = document.querySelector('.bottom-navbar')
  const bottomNavbarBtn = document.querySelector('.bottom-navbar-btn')
  document.getElementById("cookie__close").addEventListener("click", function () {
    cookiewin.classList.add('cookie_hidden');
    if (bottomNavbar) {
      bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies');
      bottomNavbarBtn.classList.remove('bottom-navbar-btn_cookie');
    }
    let date = new Date;
    date.setDate(date.getDate() + 30);
    document.cookie = `cookiecook=no; path=/; domain=.${window.location.hostname};`;
  });
} else {
  if (cookiewin)
    cookiewin.classList.add('cookie_hidden');
}